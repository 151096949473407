import { ITournamentMatch } from './tournament-match.model'
import { IParticipant } from './participant.model'
import { IAssignedRanking } from './assigned-ranking.model'
import { IVenue } from './venue.model'
import { IOverallRanking } from './overall-ranking.model'
import { IPlayerCategory } from './player-category.model'
import { TournamentStatus } from './enumerations/tournament-status.model'
import { BreakRules } from './enumerations/break-rules.model'
import { RankingCategory } from './enumerations/ranking-category.model'

export interface ITournament {
  id?: number;
  name?: string;
  mnemonic?: string;
  description?: any;
  tournamentStartDate?: string;
  tournamentEndDate?: string;
  entryLimit?: number;
  entryFee1?: number;
  entryFee1Until?: string;
  entryFee2?: number;
  entryFee2Until?: string;
  entryFee3?: number;
  entryFee3Until?: string;
  entryFeeReduced?: number;
  entryFeeReduced2?: number;
  raceTo?: number;
  tournamentStatus?: TournamentStatus;
  numberOfPlayerSeeded?: number;
  breakRule?: BreakRules;
  rankingCategory?: RankingCategory;
  numberOfTables?: number;
  timeSlotDuration?: number;
  autoApproveMatches?: boolean;
  titleForOverallRanking?: string;
  numberOfTimeouts?: number;
  currentRunningStage?: number;
  numberOfTeamMatches?: number;
  teamRaceTo1?: number;
  teamRaceTo2?: number;
  teamRaceTo3?: number;
  teamRaceTo4?: number;
  teamRaceTo5?: number;
  timeoutLengthInMinutes?: number;
  matches?: ITournamentMatch[];
  participants?: IParticipant[];
  rankings?: IAssignedRanking[];
  tournamentCategoryName?: string;
  tournamentCategoryId?: number;
  chartTemplateName?: string;
  chartTemplateId?: number;
  disciplineName?: string;
  disciplineId?: number;
  venues?: IVenue[];
  eventName?: string;
  eventId?: number;
  overallRankings?: IOverallRanking[];
  playerCategories?: IPlayerCategory[];
  teamDiscipline1Name?: string;
  teamDiscipline1Id?: number;
  teamDiscipline2Name?: string;
  teamDiscipline2Id?: number;
  teamDiscipline3Name?: string;
  teamDiscipline3Id?: number;
  teamDiscipline4Name?: string;
  teamDiscipline4Id?: number;
  teamDiscipline5Name?: string;
  teamDiscipline5Id?: number;
  eventPosterId?: number;
}

export const defaultValue: Readonly<ITournament> = {
  autoApproveMatches: false,
  currentRunningStage: 1,
  numberOfPlayerSeeded: 0,
  numberOfTimeouts: 1,
  timeoutLengthInMinutes: 5,
  breakRule: BreakRules.ALTERNATE
};
