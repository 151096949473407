import React, { useEffect, useState } from 'react'
import { IPageData, IPageProps } from '../../../../interfaces/page-data'
import { IAppState } from '../../../../redux/store'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { createEntity, getEntity, reset, updateEntity, setBlob } from '../../../../shared/reducers/model/calendar-item.reducer'
import { getEntities as getDisciplines } from '../../../../shared/reducers/model/discipline.reducer'
import { getEntities as getPlayerCategories } from '../../../../shared/reducers/model/player-category.reducer'

import { Button, Col, DatePicker, Form, Input, message, Row, Select, Space, Upload } from 'antd'
import moment from 'moment'
import { mapIdList } from '../../../../utils/entity-utils'
import { byteSize, openFile } from 'react-jhipster'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker

export interface ICalendarItemUpdateProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const CalendarItemUpdate = (props: ICalendarItemUpdateProps) => {

  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id)

  const { calendarItemEntity, onSetPage, getPageData, account } = props
  const [availableDisciplines, setAvailableDisciplines] = useState([])
  const [availablePlayerCategories, setAvailablePlayerCategories] = useState([])
  const [submitLoading, setLoadingSubmit] = useState(false)

  const pageData: IPageData = {
    title: `Sports Calendar Entry - ${!isNew ? calendarItemEntity.calendarItemTitle : 'New'}`,
    loaded: false,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Website',
        route: 'management/website',
      },
      {
        title: 'Sports Calendar',
        route: 'management/website/calendar',
      },
      {
        title: !isNew ? calendarItemEntity.calendarItemTitle : 'Create new Sports Calendar Entry',
      },
    ],
  }

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id)
    }
    props.getDisciplines(0, 99, 'name')
    props.getPlayerCategories(0, 99, 'name')
    onSetPage(pageData)
    return () => {
      props.reset()
    }
  }, [])

  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue } = form

  useEffect(() => {
    if (!props.loading && !isNew && calendarItemEntity?.id) {
      let formValues = {
        ...calendarItemEntity,
        calendarItemStartDate: moment(calendarItemEntity.calendarItemStartDate, 'YYYY-MM-DD'),
        calendarItemEndDate: moment(calendarItemEntity.calendarItemEndDate, 'YYYY-MM-DD'),
        calendarItemStartEndDate: [moment(calendarItemEntity.calendarItemStartDate, 'YYYY-MM-DD'), moment(calendarItemEntity.calendarItemEndDate, 'YYYY-MM-DD')],
        calendarItemDisciplines: calendarItemEntity.calendarItemDisciplines.map(value => value.id),
        calendarItemPlayerCategories: calendarItemEntity.calendarItemPlayerCategories.map(value => value.id),
      }
      setFieldsValue(formValues)
    }
    onSetPage({ ...pageData, loaded: true })
  }, [props.loading])

  useEffect(() => {
    if (props.updateSuccess) {
      props.history.push('/app/public/website/calendar')
    }
  }, [props.updateSuccess])

  useEffect(() => {
    if (!props.disciplinesLoading) {
      setAvailableDisciplines(props.disciplines.map(discipline => ({
        value: discipline.id,
        label: discipline.name,
      })))
    }
  }, [props.disciplinesLoading])

  useEffect(() => {
    if (!props.playerCategoriesLoading) {
      setAvailablePlayerCategories(props.playerCategories.map(category => ({
        value: category.id,
        label: category.name,
      })))
    }
  }, [props.playerCategoriesLoading])

  const onReset = () => {
    if (!isNew)
      setFieldsValue(calendarItemEntity)
    else
      form.resetFields()
  }

  const handleFormOnFinish = values => {
    setLoadingSubmit(true)
    form.validateFields()
      .then(values => {
        const entity = {
          ...calendarItemEntity,
          ...values,
          calendarItemStartDate: values.calendarItemStartEndDate[0].format('YYYY-MM-DD'),
          calendarItemEndDate: values.calendarItemStartEndDate[1].format('YYYY-MM-DD'),
          calendarItemDisciplines: values.calendarItemDisciplines ? mapIdList(values.calendarItemDisciplines) : null,
          calendarItemPlayerCategories: values.calendarItemPlayerCategories ? mapIdList(values.calendarItemPlayerCategories) : null,
        }
        if (isNew) {
          props.createEntity(entity)
        } else {
          props.updateEntity(entity)
        }
        message.success('Record successfully saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const handleFormOnFailed = errorInfo => {
    // console.log('Failed:', errorInfo)
  }

  function beforeUpload(fieldName: string, file) {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg'
    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG/PNG/SVG file!')
    }
    const fileReader: FileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = e => {
      // @ts-ignore
      const base64Data = e.target['result'].substr(e.target['result'].indexOf('base64,') + 'base64,'.length)
      props.setBlob(fieldName, base64Data, file.type)
    }
    return false
  }

  const handleDeletePoster = (event, fieldName: string) => {
    event.stopPropagation()
    props.setBlob(fieldName, undefined, undefined)
  }

  return (
    <Form form={form} id={'updateWebsiteContent'} layout={'horizontal'} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}
          onFinish={handleFormOnFinish}
          onFinishFailed={handleFormOnFailed}
    >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Form.Item name="calendarItemStartEndDate" label="Date" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}
                     initialValue={[moment(), null]}
                     rules={[{ required: true, message: 'Please enter a date' }]}
          >
            <RangePicker/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="calendarItemStatus" label="Status" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
            <Select>
              <Select.Option value={null}>-</Select.Option>
              <Select.Option value="NEW_DATE">NEW DATE</Select.Option>
              <Select.Option value="POSTPONED">POSTPONED</Select.Option>
              <Select.Option value="CANCELED">CANCELED</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name="calendarItemTitle" label="Title"
                     rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input/>
          </Form.Item>
          <Form.Item name="calendarItemLocation" label="Location"
                     rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input placeholder="City / Country"/>
          </Form.Item>
          <Form.Item name="calendarItemLink" label="Weblink">
            <Input placeholder="https://www.url-to-link.com"/>
          </Form.Item>
          <Form.Item name="calendarItemTournamentCategory" label="Tournament Category">
            <Select>
              <Select.Option value={null}>-</Select.Option>
              <Select.Option value="A">A</Select.Option>
              <Select.Option value="B">B</Select.Option>
              <Select.Option value="C">C</Select.Option>
              <Select.Option value="D">D</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="calendarItemDisciplines" label="Disciplines">
            <Select
              allowClear
              mode={'multiple'}
              loading={props.disciplinesLoading}
              options={availableDisciplines}
            />
          </Form.Item>
          <Form.Item name="calendarItemPlayerCategories" label="Player Classification">
            <Select
              allowClear
              mode={'multiple'}
              loading={props.playerCategoriesLoading}
              options={availablePlayerCategories}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Poster">
            <Form.Item name="file_poster" valuePropName="file" noStyle>
              <Upload
                name="poster"
                listType="picture-card"
                className="poster-uploader"
                showUploadList={false}
                accept="image/*"
                multiple={false}
                beforeUpload={file => beforeUpload('poster', file)}
              >
                {calendarItemEntity.poster ? (
                  <>
                    <Button shape="circle" icon={<DeleteOutlined/>} className="remove-poster-btn" onClick={event => handleDeletePoster(event, 'poster')}/>
                    <img src={`data:${calendarItemEntity.posterContentType};base64,${calendarItemEntity.poster}`} alt="poster"/>
                  </>
                ) : (
                  <div>
                    <PlusOutlined/>
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Form.Item>
          {calendarItemEntity.poster ? (
            <div>
              {calendarItemEntity.posterContentType ? (
                <a onClick={openFile(calendarItemEntity.posterContentType, calendarItemEntity.poster)}>
                  <img
                    src={`data:${calendarItemEntity.posterContentType};base64,${calendarItemEntity.poster}`}
                    style={{ maxHeight: '30px' }}
                  />
                </a>
              ) : null}
              <span>
                  {calendarItemEntity.posterContentType}, {byteSize(calendarItemEntity.poster)}
                </span>
            </div>
          ) : null}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12} offset={12} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Save
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Reset
            </Button>
            <Link to={'../calendar'}>
              <Button htmlType="button">
                Back to List
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
    </Form>
  )

}

const mapStateToProps = (storeState: IAppState) => ({
  disciplines: storeState.discipline.entities,
  disciplinesLoading: storeState.discipline.loading,
  playerCategories: storeState.playerCategory.entities,
  playerCategoriesLoading: storeState.playerCategory.loading,
  calendarItemEntity: storeState.calendarItem.entity,
  loading: storeState.calendarItem.loading,
  updating: storeState.calendarItem.updating,
  updateSuccess: storeState.calendarItem.updateSuccess,
  account: storeState.authentication.account,
})

const mapDispatchToProps = {
  getDisciplines,
  getPlayerCategories,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  setBlob,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalendarItemUpdate))
