import React, { useEffect, useState } from 'react'
import { IPageProps } from '../../../../interfaces/page-data'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { IAppState } from '../../../../redux/store'
import { Form, Input, message, Modal, Select, Switch, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { createEntityForNewsPost, reset, setBlob } from '../../../../shared/reducers/model/news-attachment.reducer'

import './website-news-posts-upload-dialog.scss'
import { NewsAttachmentType } from '../../../../shared/model/enumerations/news-attachment-type.model'

export interface IWebsiteNewsPostsUploadDialogProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

const UploadAttachmentModal: React.FC<IWebsiteNewsPostsUploadDialogProps> = (props: IWebsiteNewsPostsUploadDialogProps) => {

  const [visible, showModal] = useState(false)
  const [submitLoading, setLoadingSubmit] = useState(false)
  const [disableCoverImage, setDisableCoverImage] = useState(false)

  const newsPostId = Number(props.match.params.id)
  const [form] = Form.useForm()
  const { getFieldsValue, setFieldsValue, getFieldValue, validateFields } = form

  useEffect(() => {
    showModal(true)
  }, [newsPostId])

  const handleOnClose = () => {
    props.reset()
    props.history.goBack()
  }

  const handleOnSubmit = () => {
    setLoadingSubmit(true)
    validateFields()
      .then(values => {
        const entityToSubmit = {
          ...props.entity,
          ...values,
        }
        props.createEntityForNewsPost(entityToSubmit)
        message.success('Record successfully saved')
        setTimeout(() => {
          setLoadingSubmit(false)
          handleOnClose()
        }, 250)
      })
      .catch(reason => {
        // console.log('Validation failed:', reason)
        message.error('Record could not be saved')
        setTimeout(() => setLoadingSubmit(false), 250)
      })
  }

  const handleOnTypeChange = (value) => {
    if (value === 'IMAGE')
      setDisableCoverImage(false)
    else {
      setDisableCoverImage(true)
      setFieldsValue({ coverImage: false })
    }
  }

  function beforeUpload(fieldName: string, file) {
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg'
    const isPdf = file.type === 'application/pdf'
    const isAccepted = isImage || isPdf
    if (!isAccepted) {
      message.error('You can only upload JPG/PNG/SVG/PDF file!')
    }
    const isFileSizeOk = file.size / 1024 < 500
    if (!isFileSizeOk) {
      message.error('Image must smaller then 500 kB!')
    }

    const fileReader: FileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = e => {
      // @ts-ignore
      const base64Data = e.target['result'].substr(e.target['result'].indexOf('base64,') + 'base64,'.length)
      props.setBlob(fieldName, base64Data, file.type)
    }
    const fieldsValues = {
      name: file.name,
    }
    if (isImage) {
      setFieldsValue({ ...fieldsValues, attachmentType: 'IMAGE' })
      setDisableCoverImage(false)
    }
    if (isPdf) {
      setFieldsValue({ ...fieldsValues, attachmentType: 'DOCUMENT' })
      setDisableCoverImage(true)
      setFieldsValue({ coverImage: false })
    }
    return false
  }

  return <Modal visible={visible}
                width={700}
                title={`Upload News Attachment for News: ${props.newsPostEntity.subject}`}
                destroyOnClose
                onCancel={handleOnClose}
                onOk={handleOnSubmit}
                okText={'Upload'}
                okButtonProps={{ loading: submitLoading }}
  >
    <Form form={form} id={'uploadNewsAttachmentForm'} labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
      <Form.Item name="newsPostId" hidden initialValue={newsPostId}>
        <Input type={'hidden'}/>
      </Form.Item>
      <Form.Item name="file_attachment" label={'File'} valuePropName={'file'}>
        <Upload
          // name="avatar"
          listType="picture-card"
          className="attachment-uploader"
          showUploadList={false}
          accept="image/*,.pdf"
          multiple={false}
          beforeUpload={(file) => beforeUpload('attachment', file)}
        >
          {props.entity.attachment ? (
            <img src={`data:${props.entity.attachmentContentType};base64,${props.entity.attachment}`} alt="avatar"/>
          ) : (
            <div>
              <PlusOutlined/>
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </Form.Item>
      <Form.Item name="name" label={'Name'}>
        <Input/>
      </Form.Item>
      <Form.Item name="attachmentType" label={'Type'}>
        <Select onChange={handleOnTypeChange}>
          {Object.keys(NewsAttachmentType).map(attachmentType => (
            <Select.Option key={`type${attachmentType.toString()}`}
                           value={attachmentType.toString()}>{attachmentType.toString()}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="coverImage" label={'Cover-Image'} valuePropName={'checked'} help={'Cover images must be in 16:10 format and under 200kB file size (f.e. 640x400)'}>
        <Switch disabled={disableCoverImage}/>
      </Form.Item>
    </Form>
  </Modal>
}

const mapStateToProps = ({ newsPost, newsAttachment }: IAppState) => ({
  newsPostEntity: newsPost.entity,
  entity: newsAttachment.entity,
})

const mapDispatchToProps = {
  createEntityForNewsPost,
  setBlob,
  reset,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadAttachmentModal))
