const config = {
  VERSION: process.env.VERSION,
}

/*
const devConfig = {
  WEB_API_URL: 'http://localhost:3000',
  BACKEND_API_URL: 'http://localhost:8080',
}

const prodConfig = {
  WEB_API_URL: 'https://mng.epbf.com',
  BACKEND_API_URL: 'https://api.epbf.com',
}

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig

export default {
  ...baseConfig,
  ...config,
}
*/

export default config

export const WEB_API_URL = process.env.REACT_APP_WEB_URL
export const MNG_API_URL = process.env.REACT_APP_MNG_URL
export const BACKEND_API_URL = process.env.REACT_APP_API_URL

export const IS_LOCALSERVER = MNG_API_URL.endsWith('.local')
export const IS_LOCALDEV = MNG_API_URL.indexOf('localhost') >= 0

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN', // Administrator
  MANAGER: 'ROLE_MANAGER', // Event Administrator
  USER: 'ROLE_USER', // Tournament Leader
  PLAYER: 'ROLE_PLAYER', // ONLY FOR PLAYERS
  WEBSITE: 'ROLE_WEBSITE', // Website Administrator
  // KOZOOM: 'ROLE_KOZOOM', // Deprecated: Kozoom Access
  PHOTOGRAPHER: 'ROLE_PHOTOGRAPHER', // Photographer
  PRESS: 'ROLE_PRESS', // Press Officer
  TOURNAMENT: 'ROLE_TOURNAMENT', // Tournament Administrator
}

export const AUTHORITIES_LABELS = {
  ADMIN: 'Administrator', // Administrator
  MANAGER: 'Event Administrator', // Event Administrator
  USER: 'Tournament Leader', // Tournament Leader
  PLAYER: 'Player', // ONLY FOR PLAYERS
  WEBSITE: 'Website Administrator', // Website Administrator
  // KOZOOM: 'Deprecated', // Deprecated: Kozoom Access
  PHOTOGRAPHER: 'Photographer', // Photographer
  PRESS: 'Press Officer', // Press Officer
  TOURNAMENT: 'Tournament Administrator', // Tournament Administrator
}

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
}

export const APP_DATETIME_FORMAT_EU = 'DD.MM.YY HH:mm'
export const APP_LOCAL_DATE_FORMAT_EU = 'DD.MM.YY'
export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm'
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss'
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY'
export const APP_LOCAL_DATETIME_FORMAT_EU = 'DD.MM.YY HH:mm'
export const APP_LOCAL_DATETIME_WITH_SECONDS_FORMAT_EU = 'DD.MM.YY HH:mm:ss'
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm'
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z'
export const APP_WHOLE_NUMBER_FORMAT = '0,0'
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]'

