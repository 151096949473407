import React, { useEffect, useRef } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IPageData, IPageProps } from '../../../interfaces/page-data'
import { connect } from 'react-redux'
import axios from 'axios'

import { history, IAppState } from '../../../redux/store'

import { getActiveEntities } from '../../../shared/reducers/model/extended/billiard-table.extended.reducer'

import { Button, Card, Col, Row, Space, Tooltip } from 'antd'
import { TableStatus } from '../../../shared/model/enumerations/table-status.model'
import Title from 'antd/es/typography/Title'
import Paragraph from 'antd/es/typography/Paragraph'
import { TextFormat } from 'react-jhipster'
import { APP_DATETIME_FORMAT_EU, BACKEND_API_URL } from '../../../redux/settings/constants'
import { MatchStatus } from '../../../shared/model/enumerations/match-status.model'
import { AlertTwoTone, ClockCircleTwoTone, HourglassOutlined, LaptopOutlined, PauseCircleOutlined, SoundFilled, SyncOutlined } from '@ant-design/icons'

import './tables.scss'
import { PlaytimeStatus } from '../../../shared/model/extended/playtime-status.model'
import moment from 'moment'

export interface IOverviewTablesProps extends IPageProps, StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

const OverviewTables = (props: IOverviewTablesProps) => {

  const { match, loading, onSetPage, entities } = props
  const tablesRefreshTimeout = useRef(null)

  const pageData: IPageData = {
    title: 'Tables Overview',
    loaded: true,
    breadcrumbs: [
      {
        title: 'BitsButler',
        route: '/',
      },
      {
        title: 'Overviews',
      },
      {
        title: 'Tables',
      },
    ],
  }

  useEffect(() => {
    props.getActiveEntities(0, 999, 'displayNumber')
    onSetPage(pageData)
    tablesRefreshTimeout.current = setInterval(() => {
      props.getActiveEntities(0, 999, 'displayNumber')
    }, 15000)
    return () => {
      clearTimeout(tablesRefreshTimeout.current)
    }
  }, [])

  useEffect(() => {
    onSetPage(pageData)
    props.getActiveEntities(0, 999, 'displayNumber')
  }, [props.location]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAssignMatch = (evt, tableId: number) => {
    evt.stopPropagation()
    history.push(`/app/matches/tables/${tableId}/assignMatch`)
  }

  const handleCardClick = (tableId: number) => {
    window.open(`${BACKEND_API_URL}/web/scoreboards/${tableId}/sponsors`, '_blank')
  }

  const handleEditMatchClick = (evt, matchId) => {
    // console.log('click edit match')
    evt.stopPropagation()
  }

  const handleApproveMatchClick = (evt, matchId) => {
    // console.log('click approve match')
    evt.stopPropagation()
    axios
      .put(`/api/management/announced-matches/approve-match`, null, {
        params: {
          id: matchId,
        },
      })
      .then(() => {
        props.getActiveEntities(0, 999, 'displayNumber')
      })
  }

  let previousEventId = 0

  return (
    <Row gutter={[16, 24]} id="overviews-tables">
      {entities.map(billiardTable => {
          billiardTable.matchScheduledTime = moment(billiardTable.matchScheduledTime).utc().toString()
          return (
            <React.Fragment key={`${billiardTable.eventId}-${billiardTable.displayNumber}`}>
              {previousEventId !== billiardTable.eventId ? (
                <>
                  <Col span={24} key={`eventId-${previousEventId = billiardTable.eventId}`}>
                    <Title level={3}>Tables of Event: {billiardTable.eventName}</Title>
                  </Col>
                </>
              ) : null}
              <Col span={4} key={`col-${billiardTable.eventId}-${billiardTable.displayNumber}`}>
                <Card
                  key={`card-${billiardTable.eventId}-${billiardTable.displayNumber}`}
                  bordered
                  size={'small'}
                  style={{
                    width: '100%',
                    minHeight: 250,
                    background: billiardTable.matchStatus === MatchStatus.READY ? 'green' : billiardTable.matchStatus === MatchStatus.RUNNING ? 'green' : billiardTable.matchStatus === MatchStatus.TIMEOUT ? 'blue' : billiardTable.matchStatus === MatchStatus.FINISHED ? 'gold' : 'lightgreen',
                    border: 'brown 5px solid',
                  }}
                  title={billiardTable.matchId ? `${billiardTable.displayNumber} - ${billiardTable.tournamentCategoryMnemonic} - ${billiardTable.roundMnemonic} - ${billiardTable.matchLabel}` : `Table ${billiardTable.displayNumber}`}
                  extra={<Tooltip title="Open Scoreboard">
                    <LaptopOutlined onClick={() => handleCardClick(billiardTable.id)}
                                    style={{ color: 'white', fontSize: 20 }}/>
                  </Tooltip>}
                  headStyle={{
                    textAlign: 'center',
                    color: 'white',
                    backgroundColor: billiardTable.tableStatus === TableStatus.FREE ? 'green' : 'red',
                    borderRadius: 0,
                  }}
                  bodyStyle={{
                    textAlign: 'center',
                  }}
                >
                  {billiardTable.matchId ? (
                    <React.Fragment>
                      {/*<Button style={{position:'absolute', top: 65, right: 5}} shape={'circle'} icon={<EditOutlined />} type={'text'} onClick={event => handleEditMatchClick(event, billiardTable.matchId)} />*/}
                      {billiardTable.matchStatus === MatchStatus.READY ? (
                        <Tooltip title={`Match is assigned to the table. Waiting for start.`}>
                          <SoundFilled className={'table-button-top-left'} style={{ color: 'orange' }}/>
                        </Tooltip>
                      ) : billiardTable.matchStatus === MatchStatus.RUNNING ? (
                        <Tooltip title={'Match is running'}>
                          <SyncOutlined spin className={'table-button-top-left'} style={{ color: 'cyan' }}/>
                        </Tooltip>
                      ) : billiardTable.matchStatus === MatchStatus.TIMEOUT ? (
                        <Tooltip
                          title={`The match has a time-out.Taken from: ${billiardTable.aTimeoutUntil ? billiardTable.participantADisplayName : ''}${billiardTable.bTimeoutUntil ? (billiardTable.aTimeoutUntil ? ', ' : '') + billiardTable.participantBDisplayName : ''}`}>
                          <PauseCircleOutlined className={'table-button-top-left'} style={{ color: 'yellow' }}/>
                        </Tooltip>
                      ) : billiardTable.matchStatus === MatchStatus.FINISHED ? (
                        <Button style={{ position: 'absolute', top: 150, width: '200px', marginLeft: '-100px' }} size={'large'}
                                onClick={event => handleApproveMatchClick(event, billiardTable.matchId)}>Approve
                          Match</Button>
                      ) : null}
                      {billiardTable.matchStatus === MatchStatus.RUNNING && billiardTable.playtimeStatus && billiardTable.playtimeStatus !== PlaytimeStatus.NORMAL ? (
                        <Tooltip title={`Match is lagging - PlayTime:&nbsp;${billiardTable.playtime} - Status:&nbsp;${billiardTable.playtimeStatus}`}>
                          <AlertTwoTone twoToneColor={billiardTable.playtimeStatus}
                                        className={`table-button-top-right PLAYTIME_${billiardTable.playtimeStatus}`}/>
                        </Tooltip>
                      ) : null}
                      {billiardTable.shotclockApproved ? (
                        <Tooltip title={'Shot-Clock approved'}>
                          <ClockCircleTwoTone twoToneColor="red" className={'table-button-top-right second blinking shotClockInfo'}/>
                        </Tooltip>
                      ) : billiardTable.shotclockAssigned ? (
                        <Tooltip title={'Shot-Clock assigned'}>
                          <ClockCircleTwoTone twoToneColor="red" className={'table-button-top-right second shotClockInfo'}/>
                        </Tooltip>
                      ) : null}
                      <Paragraph style={{ color: 'white' }} key={`participantA${billiardTable.displayNumber}`}>
                        {billiardTable.participantADisplayName}
                      </Paragraph>
                      <Paragraph style={{ color: 'white', fontSize: '1.5rem' }} key={`score${billiardTable.displayNumber}`}>
                        {billiardTable.aScore} : {billiardTable.bScore}
                      </Paragraph>
                      <Paragraph style={{ color: 'white' }} key={`participantB${billiardTable.displayNumber}`}>
                        {billiardTable.participantBDisplayName}
                      </Paragraph>
                      <Paragraph style={{ color: 'white', marginBottom: 0 }}
                                 key={`matchInfo${billiardTable.displayNumber}`}>
                        {/*Match: {billiardTable.matchLabel} - {billiardTable.roundLabel}<br/>*/}
                        {/*Scheduled: */}<TextFormat value={billiardTable.matchScheduledTime}
                                                     format={APP_DATETIME_FORMAT_EU}
                                                     type={'date'}/><br/>
                        Race-to: {billiardTable.raceTo}<br/>
                        Shot-Clock: {billiardTable.shotclockApproved ? 'approved' : billiardTable.shotclockAssigned ? 'assigned' : 'no'}{/*<br/>
                        Slot-Time: {billiardTable.matchTimeSlotDuration}*/}
                      </Paragraph>
                    </React.Fragment>
                  ) : (
                    <>
                      <Space direction={'vertical'}>
                        <Button key={`btnAssignMatch${billiardTable.displayNumber}`}
                                onClick={event => handleAssignMatch(event, billiardTable.id)}>Assign Match</Button>
                      </Space>
                    </>
                  )}
                </Card>
              </Col>
            </React.Fragment>
          )
        },
      )}
    </Row>
  )
}

const mapStateToProps = (storeState: IAppState) => ({
  entities: storeState.billiardTableExtended.entities,
  loading: storeState.billiardTableExtended.loading,
})

const mapDispatchToProps = {
  getActiveEntities,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OverviewTables))