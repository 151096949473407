import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'

import './App.scss'
//import './assets/sass/styles.scss'
import { AUTHORITIES } from './redux/settings/constants'
import { connect } from 'react-redux'
import { IAppState } from './redux/store'
// import { setLocale } from './shared/reducers/locale'
import { getSession, hasAnyAuthority } from './shared/reducers/authentication'
import { getProfile } from './shared/reducers/application-profile'
import PrivateRoute from './redux/auth/private-route'
import HorizontalLayout from './layouts/Horizontal/Horizontal'
import ErrorLayout from './layouts/Error/Error'
import PublicLayout from './layouts/Public/Public'

export interface IAppProps extends StateProps, DispatchProps {
}

export const App = (props: IAppProps) => {

  useEffect(() => {
    props.getSession()
    props.getProfile()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='App'>
      <PrivateRoute path='/app' component={HorizontalLayout}/>
      <Route path='/error' component={ErrorLayout}/>
      <Route path='/public' component={PublicLayout}/>
      <Route path='/' exact render={()=><Redirect to='/app/matches/running'/>}/>
    </div>
  )
}

const mapStateToProps = ({ authentication, applicationProfile }: IAppState) => ({
  // currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isManager: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.MANAGER]),
  isInProduction: applicationProfile.inProduction,
})

const mapDispatchToProps = { getSession, getProfile }

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;


export default connect(mapStateToProps, mapDispatchToProps)(App)
