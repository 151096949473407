import { Moment } from 'moment';
import { IMatchProgressEntry } from './match-progress-entry.model';
import { MatchStatus } from './enumerations/match-status.model';
import { NextToBreak } from './enumerations/next-to-break.model';
import { TournamentMatchType } from './enumerations/tournament-match-type.model';

export interface ITournamentMatch {
  id?: number;
  matchStatus?: MatchStatus;
  matchScheduledTime?: string;
  matchStartTime?: string;
  matchDay?: number;
  raceTo?: number;
  nextToBreak?: NextToBreak;
  aScore?: number;
  aInnings?: number;
  aTimeoutUntil?: string;
  aWalkover?: boolean;
  aPlaceholder?: boolean;
  aForfeit?: boolean;
  aDisqualified?: boolean;
  aComesFromMatch?: string;
  bScore?: number;
  bInnings?: number;
  bTimeoutUntil?: string;
  bWalkover?: boolean;
  bPlaceholder?: boolean;
  bForfeit?: boolean;
  bDisqualified?: boolean;
  bComesFromMatch?: string;
  label?: string;
  type?: TournamentMatchType;
  previousAssignedTable?: string;
  aTimeoutsRemaining?: number;
  bTimeoutsRemaining?: number;
  shotclockAssigned?: boolean;
  shotclockApproved?: boolean;
  tableDisplayNumber?: string;
  tableId?: number;
  matchHistories?: IMatchProgressEntry[];
  teamMatches?: ITournamentMatch[];
  preselectedTableDisplayNumber?: string;
  preselectedTableId?: number;
  matchTemplateLabel?: string;
  matchTemplateId?: number;
  disciplineName?: string;
  disciplineId?: number;
  participantADisplayName?: string;
  participantAId?: number;
  participantA2DisplayName?: string;
  participantA2Id?: number;
  participantBDisplayName?: string;
  participantBId?: number;
  participantB2DisplayName?: string;
  participantB2Id?: number;
  tournamentName?: string;
  tournamentId?: number;
  teamParentMatchId?: number;
}

export const defaultValue: Readonly<ITournamentMatch> = {
  aWalkover: false,
  aPlaceholder: false,
  aForfeit: false,
  aDisqualified: false,
  bWalkover: false,
  bPlaceholder: false,
  bForfeit: false,
  bDisqualified: false,
  shotclockAssigned: false,
  shotclockApproved: false,
};
