import { Moment } from 'moment';
import { IInvoice } from './invoice.model';
import { Sex } from './enumerations/sex.model';
import { IPersonalBalanceHistory } from './personal-balance-history.model'

export interface IPlayerProfile {
  id?: number;
  lastName?: string;
  firstName?: string;
  nickname?: string;
  initials?: string;
  displayName?: string;
  birthday?: string;
  street?: string;
  postcode?: string;
  city?: string;
  state?: string;
  telephone?: string;
  telefax?: string;
  mobile?: string;
  websitePrivate?: string;
  sponsor?: string;
  websiteSponsor?: string;
  email?: string;
  email2?: string;
  equipment?: any;
  enabled?: boolean;
  sex?: Sex;
  registrationDate?: string;
  bankAccountName?: string;
  bankName?: string;
  bankAddress?: string;
  bankIban?: string;
  bankBic?: string;
  bankSwift?: string;
  photoContentType?: string;
  photo?: any;
  oldId?: number;
  blockedForRegistrations?: boolean;
  blockedForRegistrationsPlayerInfo?: any;
  blockedForRegistrationsAdminInfo?: any;
  teamProfile?: boolean;
  reCaptchaScore?: string;
  personalBalance?: number;
  userLogin?: string;
  userId?: number;
  invoices?: IInvoice[];
  playerCategoryName?: string;
  playerCategoryId?: number;
  countryName?: string;
  countryId?: number;
  federationCaromName?: string;
  federationCaromId?: number;
  federationPoolName?: string;
  federationPoolId?: number;
  federationSnookerName?: string;
  federationSnookerId?: number;
  nationalityName?: string;
  nationalityId?: number;
  personalBalanceHistories?: IPersonalBalanceHistory[];
}

export const defaultValue: Readonly<IPlayerProfile> = {
  enabled: false,
  blockedForRegistrations: false,
  teamProfile: false,
};
